<template>
  <div class="spinner-background">
    <i class="fa fa-cog fa-spin fa-4x"></i>
  </div>
</template>

<script>
export default {
  name: "Spinner"
};
</script>

<style scoped>
.spinner-background {
  position: fixed;
  top: -24px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
