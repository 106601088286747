<template>
  <div class="animated fadeIn">
    <Spinner v-if="isPageLoading" />
    <b-button
      v-if="!isPageLoading && MemberCharactersArray.length < 3 && !isConfirmedRequest()"
      size="md"
      variant="primary"
      class="mb-3 d-block"
      @click="$router.push('create')"
    >
      <i class="icon-plus mr-1"></i> Добавить Персонажа
    </b-button>

    <small v-if="isConfirmedRequest()" class="form-text text-muted">
      Создание нового персонажа недоступно, т.к. вы уже имеете подтвержденную заявку на ближайшее мероприятие
    </small>

    <MemberDataOwn
      v-if="currentMember && typeof currentMember.Id !== 'undefined'"
      ref="memberData"
      :member="currentMember"
      :baseURL="baseURL"
      :images="selectedMemberImages()"
      :isConfirmedRequest="isConfirmedRequest()"
      :getRank="getRank"
    ></MemberDataOwn>

    <MemberExtraCharactersData
      v-if="MemberOtherCharactersArray"
      :characters="MemberOtherCharactersArray"
      :baseURL="baseURL"
      :isConfirmedRequest="isConfirmedRequest()"
      :getRank="getRank"
    />
  </div>
</template>

<script>
import API from "../../api/stalk.net";
import MemberDataOwn from "@/components/MemberDataOwn";
import MemberExtraCharactersData from '@/components/MemberExtraCharactersData';
import Spinner from '@/components/common/Spinner';

export default {
  name: "Profile",
  components: {
    Spinner,
    MemberExtraCharactersData,
    MemberDataOwn
  },
  data() {
    return {
      isPageLoading: false,
      baseURL: API.baseURL,
      MemberCharactersArray: [],
      MemberOtherCharactersArray: [],
      currentMember: null,
      event: {},
      memberEvent: null
    };
  },
  computed: {},
  methods: {
    loadData() {
      let self = this;
      self.isPageLoading = true;
      if (localStorage.getItem("user_group") === "special") {
        this.$router.push('/system/special/jupiter');
        self.isPageLoading = false;
        return;
      }
      this.$store.state.dataLoading.then(() => {
        self.MemberCharactersArray = this.$store.state.member;
        self.currentMember = this.$store.state.member.filter(character => character.IsActive)[0]
        self.MemberOtherCharactersArray = this.$store.state.member.filter(character => !character.IsActive)

        API.public.findActiveRegEvent()
          .then(resp => {
          if (resp.data) {
            self.event = resp.data;
            return API.private.getMembersEventRequest(
              self.event.Id,
              self.currentMember.Id
            );
          }
          return Promise.reject();
        })
        .then(resp => {
          self.memberEvent = resp.data;
          this.isPageLoading = false;
        })
        .catch(self.catchError);
      });
    },
    getRank(rank) {
      return API.getRankTitle(rank);
    },
    selectedMemberImages() {
      let self = this;
      let images = [];
      self.currentMember.Photos.forEach(photo => {
        images.push(self.baseURL + photo.Path);
      });

      return images;
    },
    isConfirmedRequest() {
      return (
        this.memberEvent &&
        (this.memberEvent.Confirmation === API.consts.Request.Approved ||
          this.memberEvent.Confirmation === API.consts.Request.Paid ||
          this.memberEvent.Confirmation === API.consts.Request.PartiallyPaid)
      );
    },
    catchError(e) {
      let self = this;

      console.error(e);
      if (e.response && e.response.data && e.response.data.error) {
        console.error(e.response);
        switch (e.response.data.error) {
          case "ERR_BAD_JWT":
          case "ERR_BAD_AUTH":
          case "ERR_NOT_ALLOWED":
            {
              API.private.logout();
              self.$router.replace("/login");
            }
            break;
        }
        if (e.response.data.error !== "ERR_NOT_FOUND") {
          self.showError({message: e.response});
        }
      }
      self.isPageLoading = false;
    }
  },
  beforeMount: function() {
    this.loadData();
  },
  notifications: {
    showError: {
      title: "Ошибка ",
      type: "error"
    }
  }
};
</script>
