<template>
  <div class="characters-wrapper">
    <div v-for="character in characters">
      <div class="card characters-card" >
        <div class="card-body px-0 px-sm-4">
          <button
            v-if="!isConfirmedRequest"
            type="button"
            class="characters-edit-btn btn btn-ghost-primary"
            @click="()=>editMember(character.Id)"
            :disabled="false"
          >
            <span class="fa fa-pencil"></span>
          </button>
          <div class="row">
            <div class="col-sm-12 col-md-3">
              <h5 class="text-center">
                {{ character.Callsign }}

              </h5>
              <img
                class="image"
                v-bind:src="baseURL + character.PassportPhoto.Path"
                width="100"
                height="133"
              />
              <h3>
                <span class="badge badge-secondary ml-1">{{ character.Code }}</span>
              </h3>
            </div>
            <div class="col-sm-12 col-md-9">
              <table class="table table-hover table-borderless mb-0">
                <tbody>
                <tr>
                  <th scope="row" class="text-right">Группировка</th>
                  <td>
                    <img
                      v-if="character.Fraction.img"
                      class="option__image"
                      :src="props.option.img"
                      width="30"
                      height="30"
                      alt="."
                    />
                    <span
                      class="btn-link"
                      style="cursor: pointer"
                      @click="showModal(`fractionModal-${character.Code}`)"
                      role="button"
                    >{{ character.Fraction.Title }}</span
                    >
                  </td>
                </tr>
                <tr>
                  <th scope="row" class="text-right">Костюм</th>
                  <td>
                    <span
                      class="btn-link"
                      style="cursor: pointer"
                      @click="showModal(`suitModal-${character.Code}`)"
                      role="button"
                    >{{ character.Suit.Title }}</span
                    >
                  </td>
                </tr>
                <tr>
                  <th scope="row" class="text-right">Легенда</th>
                  <td>
                    <span
                      class="btn-link"
                      style="cursor: pointer"
                      @click="showModal(`legendModal-${character.Code}`)"
                      role="button"
                    >
                      {{
                        character.Legend.length > 25
                          ? character.Legend.substring(0, 25) + "..."
                          : character.Legend
                      }}
                    </span>
                  </td>
                </tr>
                <tr>
                  <th scope="row" class="text-right">Ранг</th>
                  <td>{{ getRank(character.Rank) }}</td>
                </tr>
                <tr>
                  <th scope="row" class="text-right">Зарегистрирован</th>
                  <td>{{ character.CreatedAt | formatDate }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <b-modal
        :ref="`legendModal-${character.Code}`"
        class="modal-info"
        size="md"
        scrollable
        ok-only
        :title="'Легенда сталкера ' + character.Callsign"
      >
        <div class="d-block text-center">
          <p>{{ character.Legend }}</p>
        </div>
      </b-modal>
      <b-modal
        :ref="`fractionModal-${character.Code}`"
        class="modal-info"
        size="md"
        scrollable
        ok-only
        :title="'Описание группировки ' + character.Fraction.Title"
      >
        <div class="d-block text-center">
          <p>{{ character.Fraction.Desc }}</p>
        </div>
      </b-modal>
      <b-modal
        :ref="`suitModal-${character.Code}`"
        class="modal-info"
        size="md"
        scrollable
        ok-only
        :title="'Описание костюма ' + character.Suit.Title"
      >
        <div class="d-block text-center">
          <p>{{ character.Suit.Desc }}</p>
        </div>
      </b-modal>
    </div>

  </div>
</template>

<script>
  import VueGallery from "vue-gallery";
  import API from "../api/stalk.net";
  import Spinner from "./common/Spinner";

  export default {
    name: "MemberExtraCharactersData",
    props: {
      characters: Array,
      images: Array,
      baseURL: String,
      isConfirmedRequest: Boolean,
      getRank: Function
    },
    data() {
      return {
        isPageLoading: false,
        index: null
      };
    },
    components: {
      Spinner,
      gallery: VueGallery
    },
    computed: {},
    filters: {},
    methods: {
      showModal(ref) {
        this.$refs[ref][0].show()
      },
      editMember(id) {
        this.$router.push({path: `/edit/${id}` });
      },
      catchError(e) {
        let self = this;

        console.error(e);
        if (e.response && e.response.data && e.response.data.error) {
          console.error(e.response.data);
          switch (e.response.data.error) {
            case "ERR_BAD_JWT":
            case "ERR_BAD_AUTH":
            case "ERR_NOT_ALLOWED": {
              API.private.logout();
              self.$router.replace("/login");
            }
              break;
          }
          self.showError({message: e.response});
        }
        self.isPageLoading = false;
      }
    }
  };
</script>

<style scoped>

  .characters-wrapper {
    display: inline-flex;
    flex-direction: column;
    position: relative;
  }
  .characters-card{
    text-align: center;
    max-width: 570px;
  }

  .characters-edit-btn {
    position: absolute;
    right: 2%;
    top: 3%;
    z-index: 1;
  }

  .image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border: 1px solid #ebebeb;
    margin: 5px;
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    -moz-filter: grayscale(100%);
    -o-filter: grayscale(100%);
  }

  #overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    -moz-opacity: 0.5;
    filter: alpha(opacity=50);
    opacity: 0.5;
    z-index: 1001;
  }

  @media (min-width: 768px) {
    .characters-card{
      text-align: left;
    }
  }
</style>
