<template>
  <div class="mr-3" style="display: inline-flex;">
    <Spinner v-if="isPageLoading" />
    <div class="card profile-card">
      <div class="card-body">
        <h5 class="card-title">
          {{ member.Callsign }}
          <button
            v-if="!isConfirmedRequest"
            type="button"
            class="btn btn-ghost-primary"
            @click="editMember"
            :disabled="false"
          >
            <span class="fa fa-pencil"></span>
          </button>
        </h5>
        <div class="row">
          <div class="col-12 col-sm-3">
              <img
                class="image"
                v-bind:src="baseURL + member.PassportPhoto.Path"
                width="100"
                height="133"
              />
              <h3>
                <span class="badge badge-secondary ml-1">{{ member.Code }}</span>
              </h3>
          </div>
          <div class="col-12 col-sm-9">
            <table class="table table-hover table-responsive table-borderless">
              <tbody>
                <tr>
                  <th scope="row" class="text-right">Группировка</th>
                  <td>
                    <img
                      v-if="member.Fraction.img"
                      class="option__image"
                      :src="props.option.img"
                      width="30"
                      height="30"
                      alt="."
                    />
                    <span
                      class="btn-link"
                      style="cursor: pointer"
                      @click="showFractionInfo()"
                      role="button"
                      >{{ member.Fraction.Title }}</span
                    >
                  </td>
                </tr>
                <tr>
                  <th scope="row" class="text-right">Костюм</th>
                  <td>
                    <span
                      class="btn-link"
                      style="cursor: pointer"
                      @click="showSuitInfo()"
                      role="button"
                      >{{ member.Suit.Title }}</span
                    >
                  </td>
                </tr>
                <tr>
                  <th scope="row" class="text-right">Легенда</th>
                  <td>
                    <span
                      class="btn-link"
                      style="cursor: pointer"
                      @click="showLegend()"
                      role="button"
                    >
                      {{
                        member.Legend.length > 25
                          ? member.Legend.substring(0, 25) + "..."
                          : member.Legend
                      }}
                    </span>
                  </td>
                </tr>
                <tr>
                  <th scope="row" class="text-right">Ранг</th>
                  <td>{{ getRank(member.Rank) }}</td>
                </tr>
                <tr>
                  <th scope="row" class="text-right">Опыт</th>
                  <td>{{ member.Experience }}</td>
                </tr>
                <tr>
                  <th scope="row" class="text-right">Деньги</th>
                  <td>{{ member.Money }} руб.</td>
                </tr>
                <tr>
                  <th scope="row" class="text-right">Зарегистрирован</th>
                  <td>{{ member.CreatedAt | formatDate }}</td>
                </tr>
              </tbody>
            </table>
            <div class="row text-center">
              <b>Фото профиля</b>
            </div>
            <div class="row">
              <gallery
                :images="images"
                :index="index"
                @close="index = null"
              ></gallery>
              <div
                class="image"
                v-for="(image, imageIndex) in images"
                :key="imageIndex"
                @click="index = imageIndex"
              >
                <img v-bind:src="image" height="133" />
              </div>
            </div>
          </div>
          <!--/.col-->
        </div>
      </div>
    </div>
    <b-modal
      ref="legendModal"
      class="modal-info"
      size="md"
      scrollable
      ok-only
      :title="'Легенда сталкера ' + member.Callsign"
    >
      <div class="d-block text-center">
        <p>{{ member.Legend }}</p>
      </div>
    </b-modal>
    <b-modal
      ref="fractionModal"
      class="modal-info"
      size="md"
      scrollable
      ok-only
      :title="'Описание группировки ' + member.Fraction.Title"
    >
      <div class="d-block text-center">
        <p>{{ member.Fraction.Desc }}</p>
      </div>
    </b-modal>
    <b-modal
      ref="suitModal"
      class="modal-info"
      size="md"
      scrollable
      ok-only
      :title="'Описание костюма ' + member.Suit.Title"
    >
      <div class="d-block text-center">
        <p>{{ member.Suit.Desc }}</p>
      </div>
    </b-modal>
  </div>
</template>

<script>
import VueGallery from "vue-gallery";
import API from "../api/stalk.net";
import Spinner from "./common/Spinner";

export default {
  name: "MemberDataOwn",
  props: {
    baseURL: String,
    member: Object,
    images: Array,
    isConfirmedRequest: Boolean,
    getRank: Function
  },
  data() {
    return {
      isPageLoading: false,
      index: null
    };
  },
  components: {
    Spinner,
    gallery: VueGallery
  },
  computed: {},
  filters: {},
  methods: {
    showLegend() {
      this.$refs.legendModal.show();
    },
    showFractionInfo() {
      this.$refs.fractionModal.show();
    },
    showSuitInfo() {
      this.$refs.suitModal.show();
    },
    editMember() {
      this.$router.push({ path: `/edit/${this.member.Id}` });
    },
    catchError(e) {
      let self = this;

      console.error(e);
      if (e.response && e.response.data && e.response.data.error) {
        console.error(e.response.data);
        switch (e.response.data.error) {
          case "ERR_BAD_JWT":
          case "ERR_BAD_AUTH":
          case "ERR_NOT_ALLOWED":
            {
              API.private.logout();
              self.$router.replace("/login");
            }
            break;
        }
        self.showError({ message: e.response });
      }
      self.isPageLoading = false;
    }
  }
};
</script>

<style scoped>

.profile-card{
  max-width: 550px;
  text-align: center;
}

.image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}

#overlay {
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  background-color: black;
  -moz-opacity: 0.5;
  filter: alpha(opacity=50);
  opacity: 0.5;
  z-index: 1001;
}

@media (min-width: 568px) {
  .profile-card{
    text-align: left;
  }
}
</style>
